<template>
  <b-card>
    <div class="d-flex">
      <b-form-input
          id="filter-input"
          v-model="filter"
          type="search"
          class="col-md-4 col-xl-2"
          placeholder="Cerca..."
      ></b-form-input>
      <b-button class="mb-3 ml-auto" variant="success" v-b-modal.editModalVector
                @click="newItem"
      >+ Crea Nuovo</b-button
      >
    </div>
    <b-table
        hover
        bordered
        :filter="filter"
        responsive
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        class="contact-table"
    >
      <!-- A custom formatted column -->
      <template #cell(Azioni)="data" width="100" class="text-center">
        <a class="mx-1 cursor-pointer" @click="editItem(data.item)"
        ><feather type="edit-3" class="feather-sm"></feather
        ></a>
        <a
            class="mx-1 cursor-pointer text-danger"
            @click="deleteItem(data.item)"
        ><feather type="trash-2" class="text-danger feather-sm"></feather
        ></a>
      </template>
    </b-table>
    <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        align="center"
    ></b-pagination>
    <!-- ====================================== -->
    <!-- Edit Contact box -->
    <!-- ====================================== -->
    <b-modal
        ref="edit-modal-vector"
        id="editModalVector"
        size="xl"
        hide-header
        @ok="save"
        @cancel="close"
        ok-title="Salva"
        cancel-title="Chiudi"
    >
      <h4 class="mb-4">{{ formTitle }}</h4>
      <b-row>
        <b-col cols="12" lg="6" class="mb-3">
          <h6>Name</h6>
          <b-form-input
              v-model="editedItem.authinfo.name"
              placeholder="Inserisci Nome"
              name="name-group"
              size="lg"
          ></b-form-input>
        </b-col>
        <b-col cols="12" lg="6" class="mb-3">
          <h6>Email</h6>
          <b-form-input
              v-model="editedItem.authinfo.email"
              placeholder="Inserisci email"
              name="name-group"
              size="lg"
          ></b-form-input>
        </b-col>
        <b-col cols="12" lg="6" class="mb-3">
          <h6>Email per notifiche</h6>
          <b-form-input
              v-model="editedItem.email_alert"
              placeholder="Inserisci email da inviare notifiche"
              name="name-group"
              size="lg"
          ></b-form-input>
        </b-col>
        <b-col cols="12" lg="6" class="mb-3">
          <h6>Password</h6>
          <b-form-input
              v-model="editedItem.authinfo.password"
              placeholder="Inserisci password"
              name="name-group"
              size="lg"
          ></b-form-input>
        </b-col>
      </b-row>
      <h4 class="mb-4">Servizi</h4>
      <b-row>
        <b-col cols="12" lg="12" class="mb-3">
          <template v-for="serv in editedItem.available_services">
            <b-row :key="serv.id">
              <b-col cols="12" lg="6" class="mb-3">
                <b-form-checkbox v-model="serv.checked" :key="serv.id" name="check-button" switch>
                  {{ serv.description }}
                </b-form-checkbox>
              </b-col>
              <b-col v-if="serv.checked" cols="12" lg="6" class="mb-3">
                <h6>Costo servizio (€)</h6>
                <b-form-input type="number" size="lg" min="0.50" step="0.1" placeholder="0" v-model="serv.cost_by_km" @keyup="preventCostKm(serv)"></b-form-input>
              </b-col>
            </b-row>
          </template>
        </b-col>
        <b-col cols="12" lg="6" class="mb-3">
          <h6>Numero veicoli</h6>
          <b-form-input type="number" size="lg" min="1" placeholder="0" v-model="editedItem.number_ambulance" @keyup="preventNumberInput"></b-form-input>
        </b-col>
        <b-col cols="12" lg="6" class="mb-3">
          <h6>Costo per Km (€) generico</h6>
          <b-form-input type="number" size="lg" min="0.50" step="0.1" placeholder="0" v-model="editedItem.cost_by_km" @keyup="preventCostKm(editedItem)"></b-form-input>
        </b-col>
        <b-col cols="12" lg="6" class="mb-3">
          <h6>IBAN</h6>
          <b-form-input type="text" size="lg" v-model="editedItem.stripe_iban"></b-form-input>
        </b-col>
      </b-row>
    </b-modal>
  </b-card>
</template>

<script>
export default {
  name: "VectorList",
  components: {
    //TypeServiceSelectTag: () => import('@/views/type-of-services/components/SelectTags')
  },
  data: () => ({
    sortBy: "id",
    checked: false,
    fields: [
      { key: "id", sortable: true },
      { key: "authinfo.name", label: "Nome" },
      { key: "authinfo.email", label: "Email" },
      "Azioni",
    ],
    filter: null,
    totalRows: 1,
    currentPage: 1,
    perPage: 5,
    url: null,
    editedIndex: -1,
    editedItem: {
      id: "",
      email_alert: "",
      authinfo: {
        name: '',
        email: '',
        password: '',
      },
      available_services: [],
      stripe_iban: '',
      number_ambulance: 1,
      cost_by_km: 1,
    },
    defaultItem: {
      id: "",
      email_alert: "",
      authinfo: {
        name: '',
        email: '',
        password: '',
      },
      available_services: [],
      stripe_iban: '',
      number_ambulance: 1,
      cost_by_km: 1,
    },
    typeOfServices: []
  }),
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.$store.dispatch("setDataTable", [])
      const res = await this.axios.get('user/vectors/')
      await this.$store.dispatch("setDataTable", res.data)

      const typeOfServices = await this.axios.get('type-of-services')
      this.typeOfServices = typeOfServices.data
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = item;
      this.$refs["edit-modal-vector"].show();
    },

    newItem() {
      this.defaultItem.available_services = this.typeOfServices
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },

    async deleteItem(item) {
      const index = this.items.indexOf(item);
      await this.$swal.fire({
        title: 'Elimina Utente',
        text: 'Sei sicuro di voler eliminare '+item.authinfo.name+'?',
        icon: 'warning',
        confirmButtonColor: '#1f2335',
        confirmButtonText: 'Si, Elimina!',
        showCancelButton: true,
        cancelButtonText: 'No!'
      }).then(async result => {
        if (result.value) {
          const res = await this.axios.delete('user/vector/'+item.id)
          if (res.data.status === 'success') {
            this.items.splice(index, 1);
          }

          this.$swal.fire({
            title: 'Eliminazione Utente',
            text: res.data.message,
            icon: res.data.status,
            confirmButtonColor: '#1f2335',
          })
        }
      });

      await this.initialize()
    },
    close() {
      this.$refs["edit-modal-vector"].hide();
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    async save() {
      // this.editedItem.available_services = this.$refs.serviceTag.value
      const res = await this.axios.post('user/vector', this.editedItem)
      this.$swal.fire({
        title: 'Salvataggio Utente',
        text: res.data.message,
        icon: res.data.status,
        confirmButtonColor: '#1f2335',
      })
      this.close();
      await this.initialize();
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.editedItem.avatar = URL.createObjectURL(file);
    },
    preventNumberInput() {
      const that = this
      setTimeout(function () {
        if (that.editedItem.number_ambulance < 1) {
          that.editedItem.number_ambulance = 1
        }
      }, 300)
    },
    preventCostKm(editedItem) {
      if (editedItem.cost_by_km < 0.5) {
        editedItem.cost_by_km = 0.5
      }
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuovo Vettore" : "Modifica Vettore";
    },
    rows() {
      return this.items.length;
    },
    items () {
      return this.$store.state.dataTable
    }
  },
  watch: {},
};
</script>

<style>
.contact-table td {
  vertical-align: middle;
}
</style>